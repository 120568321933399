import { RouteComponentProps, Router } from "@reach/router"
import React from "react"
import SellPageV1 from "./create/SellPageV1"

export default function ProductRouter() {
  return (
    <Router basepath="/create" component={({ children }) => <>{children}</>}>
      <SellPageV1 path="/" />
    </Router>
  )
}
